<template>
	<w-layout row wrap>
		<v-flex v-for="(email, index) in value" :key="index" xs12>
			<v-layout row wrap align-center>
				<v-flex :xs10="index > 0" :xs12="index === 0">
					<w-email-input 
						v-model.trim="copiedValue[index]" 
						:validate-on-blur="validateOnBlur" 
						:label="label" 
						:required="required" 
						@input="onInput()"></w-email-input>
				</v-flex>
				<v-flex v-if="index > 0" xs2>
					<w-btn-delete  mini @click="removeEmail(index)" />
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex xs12>
			<w-layout justify-center>
				<w-btn @click="addEmail()">Ajouter une adresse</w-btn>
			</w-layout>
		</v-flex>
	</w-layout>
</template>

<script>
 /* eslint-disable */ 
export default {
	name: 'WMultipleEmailInput',
	mixins: [],
	props: {
		value: {
			required: false,
			type: Array,
			default: () => []
		},
		validateOnBlur: {
			default: true,
			required: false,
			type: Boolean
		},
		required: {
			required: false,
			type: Boolean,
			default: true
		},
		label: {
			required: false,
			type: String,
			default: ''
		},
	},
	data: function () {
		return {}
	},
	computed: {
		copiedValue: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	},
	watch: {
	},
	created: function () {
	},
	mounted: function () {
		if (this.copiedValue.length === 0) {
			this.copiedValue.push('')
		}
	},
	destroyed: function () {
	},
	methods: {
		addEmail: function() {
			this.copiedValue.push('')
		},
		removeEmail: function (index) {
			this.copiedValue.splice(index, 1)
			this.$emit('input', this.copiedValue)
		},
		onInput: function () {
			this.$emit('input', this.copiedValue)
		},
	}
}
</script>
